.main-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.main-wrapper .addon-wrapper {
  position: absolute;
  right: 0;
  display: flex;
}

.main-wrapper .crop-wrapper {
  align-self: center;
  position: relative;
}

.input-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.input-wrapper .input-icon-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-wrapper input {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.canvas-wrapper {
  display: flex;
  position: relative;
}

.canvas-wrapper .actions-wrapper {
  position: absolute;
  right: 0;
  cursor: pointer;
  display: flex;
}

.dialog-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
}

.saved-wrapper {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.saved-wrapper:hover {
  opacity: 1;
  transition: opacity .15s;
}

.preview {
  width: 100%;
  object-fit: cover;
  height: 100%;
}